function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import compact from 'lodash/compact';
import { RecurrencePeriodTypes, ServiceCostType } from "../../../models/service/model";
import { BillingTriggerType } from "../../../types/api.model";
import { DiscountType, serviceModel } from "../../../models";
import Decimal from 'decimal.js';
import { utils } from "../../../utils";
import { makeAwaitingApprovalStatus } from "../../../models/service";
import { PracticeManagementServiceIntegrationModel, QboServiceIntegrationModel, ServiceIntegrationsModel } from "../../../models/ServiceIntegrations";
export const defaultDiscountType = DiscountType.Percent;
export function getPriceAfterDiscount(data) {
  const {
    price,
    minPrice,
    pricing,
    discount
  } = data;
  if (!price || !discount.amount || !+discount.amount) return '';
  const priceWithDiscount = utils.formatDollarAmount(utils.applyDiscount(new Decimal(+price), {
    amount: new Decimal(+discount.amount),
    type: discount.type
  }));
  if (pricing === 'range') {
    const minPriceWithDiscount = utils.formatDollarAmount(utils.applyDiscount(new Decimal(+minPrice), {
      amount: new Decimal(+discount.amount),
      type: discount.type
    }));
    return "".concat(minPriceWithDiscount, "-").concat(priceWithDiscount);
  }
  return priceWithDiscount;
}
export function outputToService(output, originalService) {
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  const {
    serviceName,
    description,
    trigger,
    recurrencePeriod,
    pricing,
    minPrice
  } = output;
  const {
    applyOutputToChanges
  } = options;
  const discount = (() => {
    const amount = output.discount.amount && +output.discount.amount;
    if (!amount) return null;
    return output.discount.type === 'percent' ? serviceModel.makePercentDiscount(amount) : serviceModel.makeValueDiscount(amount);
  })();
  const cost = (() => {
    const price = +output.price;
    const priceIncrease = originalService && serviceModel.priceIncrease(originalService);
    switch (pricing) {
      case ServiceCostType.Fixed:
        return serviceModel.makeFixedCost(price, {
          discount,
          priceIncrease
        });
      case ServiceCostType.Range:
        return serviceModel.makeRangeCost(price, {
          minPrice: minPrice ? +minPrice : null,
          discount,
          priceIncrease
        });
      default:
        return serviceModel.makeVariableCost(price, {
          discount,
          unitName: pricing === 'unit' && output.unitName || null,
          unitCap: output.unitCap ? +output.unitCap : 0,
          priceIncrease
        });
    }
  })();
  const billing = (() => {
    if (recurrencePeriod === 'onetime') {
      return trigger === 'approval' ? serviceModel.makeOnApprovalBilling() : trigger === 'delivery' ? serviceModel.makeOneTimeDeliveryBilling() : serviceModel.makeOneTimeManualBilling();
    } else {
      const billingDayOfMonth = originalService ? serviceModel.billingDayOfMonth(originalService) : null;
      const billingDayOfWeek = originalService && serviceModel.billingDayOfWeek(originalService) || 'Monday';
      const isBilledUpfront = (() => {
        if (originalService) {
          const isBilledUpfront = serviceModel.isBilledUpfront(originalService);
          if (isBilledUpfront != null) return isBilledUpfront;
        }
        return false;
      })();
      const maxCharges = originalService && serviceModel.maxCharges(originalService);
      return trigger === 'ongoing' ? serviceModel.makeOngoingBilling(recurrencePeriod, {
        billingDayOfMonth,
        billingDayOfWeek,
        isBilledUpfront,
        billUpfrontState: serviceModel.makeBillUpfrontState(!!(originalService && serviceModel.isSkipBillingOnAcceptance(originalService))),
        isPaused: originalService && serviceModel.isPaused(originalService),
        isProrated: originalService && serviceModel.isProrated(originalService),
        maxCharges,
        isAwaitingApproval: !originalService || serviceModel.isAwaitingApproval(originalService.status)
      }) : serviceModel.makeRepeatableManualBilling(recurrencePeriod, {
        billingDayOfMonth,
        billingDayOfWeek,
        isBilledUpfront: false,
        maxCharges
      });
    }
  })();
  if (applyOutputToChanges && originalService) {
    const originalNote = serviceModel.note(originalService);
    const withChanges = serviceModel.setChanges(originalService, {
      name: serviceName,
      description,
      cost,
      billing
    });
    return originalNote ? serviceModel.setNote(withChanges, originalNote) : withChanges;
  } else {
    const qboIntegratedId = output.serviceMapping.type === 'unmapped' ? undefined : output.serviceMapping.mappedItem.id;
    return serviceModel.makeService(_objectSpread(_objectSpread({}, originalService || {}), {}, {
      status: originalService ? originalService.status : makeAwaitingApprovalStatus(),
      name: serviceName,
      description,
      cost,
      billing,
      integratedId: qboIntegratedId,
      integrations: originalService ? originalService.integrations.setKarbonIntegratedIds(output.karbonMapping).setFinancialCentsIntegratedIds(output.financialCentsMapping).setQboIntegratedId(qboIntegratedId) : output.financialCentsMapping.length || output.karbonMapping.length || qboIntegratedId ? new ServiceIntegrationsModel({
        karbon: PracticeManagementServiceIntegrationModel.makeKarbon({
          integratedIds: output.karbonMapping
        }),
        financialCents: PracticeManagementServiceIntegrationModel.makeFinancialCents({
          integratedIds: output.financialCentsMapping
        }),
        qbo: new QboServiceIntegrationModel(qboIntegratedId || null, null)
      }) : ServiceIntegrationsModel.fromDTO(undefined)
    }));
  }
}
export function serviceToInput(service, qboItems) {
  const {
    name,
    description,
    billing,
    cost
  } = service;
  return {
    serviceName: name,
    description,
    recurrencePeriod: (() => {
      switch (billing.trigger) {
        case BillingTriggerType.OnApproval:
        case BillingTriggerType.OneTimeManual:
        case BillingTriggerType.OneTimeDelivery:
          return 'onetime';
        default:
          return billing.recurrencePeriod;
      }
    })(),
    pricing: (() => {
      switch (cost.pricingType) {
        case ServiceCostType.Fixed:
        case ServiceCostType.Range:
          return cost.pricingType;
        default:
          return cost.unitName ? 'unit' : 'hour';
      }
    })(),
    price: (() => {
      switch (cost.pricingType) {
        case ServiceCostType.Fixed:
        case ServiceCostType.Variable:
          return cost.price.toString();
        case ServiceCostType.Range:
        default:
          return cost.maxPrice.toString();
      }
    })(),
    minPrice: (() => {
      if (cost.pricingType !== 'range') return '';
      return cost.minPrice != null ? cost.minPrice.toString() : '';
    })(),
    unitName: cost.pricingType === 'variable' && cost.unitName || '',
    discount: {
      type: cost.discount ? cost.discount.type : defaultDiscountType,
      amount: cost.discount ? cost.discount.amount.toString() : ''
    },
    unitCap: cost.pricingType === 'variable' && cost.unitCap != null && cost.unitCap.toString() || '',
    trigger: (() => {
      if (billing.trigger === BillingTriggerType.OnApproval) {
        return 'approval';
      } else if (billing.trigger === BillingTriggerType.Ongoing) {
        return 'ongoing';
      } else if (billing.trigger === BillingTriggerType.OneTimeDelivery) {
        return 'delivery';
      } else if (cost.pricingType === 'fixed') {
        return 'manualFixed';
      } else {
        return 'manualVariable';
      }
    })(),
    serviceMapping: getDefaultServiceMapping(service.integratedId, qboItems),
    karbonMapping: service.integrations.karbonIntegratedIds(),
    financialCentsMapping: service.integrations.financialCentsIntegratedIds()
  };
}
export function getDefaultServiceMapping(integratedId, qboItems) {
  const mappedItem = qboItems.find(qboItem => qboItem.id === integratedId);
  return integratedId && mappedItem ? {
    type: 'mapped',
    mappedItem
  } : {
    type: 'unmapped'
  };
}
export function getBillingTriggerOptions(data, serviceOrigin, isExistingService) {
  const {
    recurrencePeriod,
    pricing,
    trigger
  } = data;
  const fromAgreement = serviceOrigin === 'agreement';
  const isOneTime = recurrencePeriod === 'onetime';
  const isYearly = recurrencePeriod === RecurrencePeriodTypes.Yearly;
  const isVariablePrice = pricing === 'hour' || pricing === 'unit';
  const isFixedPrice = pricing === 'fixed';
  const isRangePrice = pricing === 'range';
  const isOneTimeManual = recurrencePeriod === 'onetime' && trigger !== 'approval';
  const isAmendment = isExistingService && fromAgreement;
  const isApprovalOptionHidden = isAmendment && isOneTimeManual;
  return compact([!isVariablePrice && !isRangePrice && (isOneTime ? !isApprovalOptionHidden && {
    value: 'approval',
    text: fromAgreement ? 'Automatic' : "Automatic, on agreement approval",
    subtext: fromAgreement ? 'An invoice will be issued immediately once amendments are saved and payment will require client approval' : 'An invoice will be sent on agreement approval without requiring any action on your end'
  } : !isYearly && {
    value: 'ongoing',
    text: 'Automatic, on schedule',
    subtext: 'Invoices will be automatically sent based on the billing period set in the agreement'
  }), isFixedPrice ? {
    value: 'manualFixed',
    text: 'Manual',
    subtext: 'You will be required to trigger the invoice'
  } : {
    value: 'manualVariable',
    text: 'Manual, pending data entry',
    subtext: 'You will be required to input the total in order to trigger the invoice'
  }]);
}
export function getOccurrenceDisabledData(serviceOrigin, service) {
  const editingApprovedService = !!service && !serviceModel.isAwaitingApproval(service.status);
  const editingServiceFromAgreement = editingApprovedService && serviceOrigin === 'agreement';
  return {
    disabled: editingServiceFromAgreement,
    tooltip: editingServiceFromAgreement ? 'Billing occurrences cannot be modified; to modify an occurrence, create a new service and add it to the agreement.' : ''
  };
}
export function isNameFieldDisabled(service, serviceOrigin) {
  return !!service && serviceOrigin === 'agreement' && !serviceModel.isAwaitingApproval(service.status);
}