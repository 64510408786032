import { fromDatestampObject } from "./time";
import { MoneyModel } from "./MoneyModel";
export class PayoutsSummaryModel {
  static fromDTO(dto) {
    return new PayoutsSummaryModel({
      paidThisMonth: MoneyModel.fromDTO(dto.paidThisMonth),
      payouts: dto.payouts.map(payout => ({
        date: fromDatestampObject(payout.date),
        amount: MoneyModel.fromDTO(payout.amount)
      }))
    });
  }
  constructor(model) {
    this.model = model;
  }
  formattedPaidThisMonth() {
    return this.model.paidThisMonth.display();
  }
  isEmpty() {
    return this.model.payouts.length === 0;
  }
}