import Decimal from 'decimal.js';
import { BillingTriggerType } from "../../types/api.model";
import { RecurrencePeriodTypes, ServiceCostType } from "../service/model";
import { utils } from "../../utils";
import * as serviceModel from "../service";
import { makeServiceTemplate, makeBillingOnApproval, makeBillingOneTimeManual, makeBillingOneTimeDelivery, makeBillingOngoing, makeBillingRepeatableManual, generateId } from "./makers";
import { defaultBillingDayOfWeek } from "../../constants";
import { PriceIncreaseType } from "../price-increase";
import { BillingDayOfMonthModel } from "../BillingDayOfMonth";
import { ServiceIntegrationsModel } from "../ServiceIntegrations";
export function costFromDTO(cost) {
  const discount = cost.discount != null ? {
    type: cost.discount.type,
    amount: new Decimal(utils.centsToDollars(cost.discount.decimal))
  } : null;
  if (cost.pricingType.type === ServiceCostType.Fixed) {
    return serviceModel.makeFixedCost(utils.centsToDollars(cost.pricingType.fixed.price), {
      discount
    });
  } else if (cost.pricingType.type === ServiceCostType.Variable) {
    return serviceModel.makeVariableCost(utils.centsToDollars(cost.pricingType.variable.unitPrice), {
      discount,
      unitCap: cost.pricingType.variable.cap != null ? utils.centsToDollars(cost.pricingType.variable.cap) : null,
      unitName: cost.pricingType.variable.name
    });
  } else {
    return serviceModel.makeRangeCost(utils.centsToDollars(cost.pricingType.range.maxPrice), {
      discount,
      minPrice: cost.pricingType.range.minPrice != null ? utils.centsToDollars(cost.pricingType.range.minPrice) : null
    });
  }
}
export function costToDTO(cost) {
  const discount = cost.discount ? {
    type: cost.discount.type,
    decimal: utils.dollarsToCents(cost.discount.amount)
  } : null;
  const priceIncrease = {
    type: PriceIncreaseType.None
  };
  switch (cost.pricingType) {
    case ServiceCostType.Fixed:
      return {
        discount,
        priceIncrease,
        pricingType: {
          type: ServiceCostType.Fixed,
          fixed: {
            price: utils.dollarsToCents(cost.price)
          },
          variable: null,
          range: null
        }
      };
    case ServiceCostType.Variable:
      return {
        discount,
        priceIncrease,
        pricingType: {
          type: ServiceCostType.Variable,
          fixed: null,
          variable: {
            unitPrice: utils.dollarsToCents(cost.price),
            cap: cost.unitCap != null ? utils.dollarsToCents(cost.unitCap) : null,
            name: cost.unitName
          },
          range: null
        }
      };
    case ServiceCostType.Range:
      return {
        discount,
        priceIncrease,
        pricingType: {
          type: ServiceCostType.Range,
          fixed: null,
          variable: null,
          range: {
            minPrice: cost.minPrice != null ? utils.dollarsToCents(cost.minPrice) : null,
            maxPrice: utils.dollarsToCents(cost.maxPrice)
          }
        }
      };
  }
}
export function billingFromDTO(billedUpon) {
  switch (billedUpon.type) {
    case BillingTriggerType.OnApproval:
      return makeBillingOnApproval();
    case BillingTriggerType.OneTimeManual:
      return makeBillingOneTimeManual();
    case BillingTriggerType.OneTimeDelivery:
      return makeBillingOneTimeDelivery();
    case BillingTriggerType.Ongoing:
      return makeBillingOngoing(billedUpon.ongoing.recurrence.period.type);
    case BillingTriggerType.RepeatableManual:
      return makeBillingRepeatableManual(billedUpon.repeatableManual.recurrence.period.type);
  }
}
export function billingToDTO(billing) {
  switch (billing.trigger) {
    case BillingTriggerType.OnApproval:
      return {
        type: BillingTriggerType.OnApproval
      };
    case BillingTriggerType.OneTimeManual:
      return {
        type: BillingTriggerType.OneTimeManual
      };
    case BillingTriggerType.OneTimeDelivery:
      return {
        type: BillingTriggerType.OneTimeDelivery
      };
    case BillingTriggerType.Ongoing:
      return {
        type: BillingTriggerType.Ongoing,
        ongoing: {
          isPaused: false,
          prorateFirstInvoice: false,
          billUpfront: false,
          maxCharges: {
            enabled: false,
            limit: null
          },
          recurrence: {
            period: {
              type: billing.recurrencePeriod,
              monthly: billing.recurrencePeriod === RecurrencePeriodTypes.Monthly ? {
                dayOfMonth: BillingDayOfMonthModel.make().toDBType()
              } : null,
              weekly: billing.recurrencePeriod === RecurrencePeriodTypes.Weekly ? {
                day: defaultBillingDayOfWeek
              } : null,
              biweekly: billing.recurrencePeriod === RecurrencePeriodTypes.BiWeekly ? {
                day: defaultBillingDayOfWeek
              } : null
            }
          }
        }
      };
    case BillingTriggerType.RepeatableManual:
      return {
        type: BillingTriggerType.RepeatableManual,
        repeatableManual: {
          billUpfront: false,
          maxCharges: {
            enabled: false,
            limit: null
          },
          recurrence: {
            period: {
              type: billing.recurrencePeriod,
              monthly: billing.recurrencePeriod === RecurrencePeriodTypes.Monthly ? {
                dayOfMonth: BillingDayOfMonthModel.make().toDBType()
              } : null,
              weekly: billing.recurrencePeriod === RecurrencePeriodTypes.Weekly ? {
                day: defaultBillingDayOfWeek
              } : null,
              biweekly: billing.recurrencePeriod === RecurrencePeriodTypes.BiWeekly ? {
                day: defaultBillingDayOfWeek
              } : null
            }
          }
        }
      };
  }
}
export function fromService(service) {
  const {
    billing
  } = service;
  return makeServiceTemplate({
    id: service.serviceTemplateId,
    name: service.name,
    description: service.description,
    integratedId: service.integratedId,
    cost: service.cost,
    integrations: service.integrations,
    billing: serviceModel.isOnApproval(billing) ? makeBillingOnApproval() : serviceModel.isOneTimeManual(billing) ? makeBillingOneTimeManual() : serviceModel.isOneTimeDelivery(billing) ? makeBillingOneTimeDelivery() : serviceModel.isOngoing(billing) ? makeBillingOngoing(billing.recurrencePeriod) : makeBillingRepeatableManual(billing.recurrencePeriod)
  });
}
export function fromDTO(dto) {
  return {
    id: dto.id,
    name: dto.name,
    description: dto.description,
    status: dto.status,
    cost: costFromDTO(dto.cost),
    billing: billingFromDTO(dto.billedUpon),
    integratedId: dto.integratedId || null,
    origin: dto.origin || null,
    integrations: ServiceIntegrationsModel.fromDTO(dto.integrations)
  };
}
export function toDTO(service) {
  const dto = {
    id: service.id || generateId(),
    name: service.name,
    description: service.description,
    status: service.status,
    cost: costToDTO(service.cost),
    billedUpon: billingToDTO(service.billing)
  };
  if (service.integratedId) {
    dto.integratedId = service.integratedId;
  }
  if (service.origin) {
    dto.origin = service.origin;
  }
  if (service.integrations.hasIntegrations()) {
    dto.integrations = service.integrations.toJSON();
  }
  return dto;
}