import { integrationModel, qboIntegrationModel } from "../../models";
import { userSelectors } from "./userSelectors";
import { ff } from "./ffSelectors";
const integrations = s => s.integrations.data;
const qboIntegration = s => integrationModel.qboIntegration(integrations(s));
const isLinkedToQbo = s => {
  const qbo = qboIntegration(s);
  return !!qbo && qboIntegrationModel.isLinked(qbo);
};
const qboItems = s => s.qboItems.data;
const showQboIntegration = s => isLinkedToQbo(s) && userSelectors.isVendor(s);
const allowToCustomizeQboClasses = s => {
  const qbo = qboIntegration(s);
  return !!qbo && qboIntegrationModel.isLinked(qbo) && qboIntegrationModel.classTrackingAgreementOverride(qbo);
};
const isPendingQboIntegrationSetup = s => {
  var _qboIntegration$accou, _qboIntegration;
  const accountsList = Object.values((_qboIntegration$accou = (_qboIntegration = qboIntegration(s)) === null || _qboIntegration === void 0 ? void 0 : _qboIntegration.accounts) !== null && _qboIntegration$accou !== void 0 ? _qboIntegration$accou : {});
  const isValidAccountsLength = accountsList.length === 3;
  const areAllAccountsSynced = accountsList.every(account => Boolean(account.synced));
  return !isValidAccountsLength || !areAllAccountsSynced;
};
const showInvoiceQboSection = s => !!(showQboIntegration(s) && !isPendingQboIntegrationSetup(s));
const financialCentsIntegration = s => integrationModel.financialCentsIntegration(integrations(s));
const karbonIntegration = s => integrationModel.karbonIntegration(integrations(s));
const isLinkedToFinancialCents = s => {
  const financialCents = financialCentsIntegration(s);
  return !!financialCents && integrationModel.isLinked(financialCents);
};
const isLinkedToKarbon = s => {
  const karbon = integrationModel.karbonIntegration(integrations(s));
  return !!karbon && integrationModel.isLinked(karbon);
};
const isLinkedToClientHub = s => {
  const clientHub = integrationModel.clientHubIntegration(integrations(s));
  return !!clientHub && integrationModel.isLinked(clientHub);
};
const showFinancialCentsIntegration = s => !!ff.featureFlagsData(s).FINANTIAL_CENTS && isLinkedToFinancialCents(s) && userSelectors.isVendor(s);
const financialCentsItems = s => s.financialCents.items.data;
const financialCentsUsers = s => s.financialCents.users.data;
const showKarbonIntegration = s => !!ff.featureFlagsData(s).KARBON && isLinkedToKarbon(s) && userSelectors.isVendor(s);
const showClientHubIntegration = s => !!ff.featureFlagsData(s).CLIENT_HUB && isLinkedToClientHub(s) && userSelectors.isVendor(s);
const karbonItems = s => s.karbon.items.data;
const karbonUsers = s => s.karbon.users.data;
export const integrationSelectors = {
  integrations,
  qboIntegration,
  isLinkedToQbo,
  qboItems,
  showQboIntegration,
  isPendingQboIntegrationSetup,
  showInvoiceQboSection,
  allowToCustomizeQboClasses,
  financialCentsIntegration,
  financialCentsItems,
  financialCentsUsers,
  showFinancialCentsIntegration,
  karbonItems,
  karbonUsers,
  showKarbonIntegration,
  karbonIntegration,
  showClientHubIntegration
};