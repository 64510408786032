import { ServerError } from "../../infra/ErrorResponse";
export function getCreateRelationshipError(err) {
  return err.hasServerError(ServerError.BuyerUserAssociatedWithVendor) ? {
    id: 'member',
    text: 'Proposals cannot be sent to someone within your own business. Please change contact'
  } : err.hasPhoneValidationError() ? {
    id: 'phone',
    text: 'The proposal was not sent because of an invalid phone number, please verify and send again'
  } : err.hasServerError(ServerError.NameAlreadyExists) ? {
    id: 'name',
    text: 'A template with this name already exists.'
  } : {
    id: 'unknown',
    text: 'An internal error occurred, please contact support for further help.'
  };
}
export const defaultCoverLetterNewClient = "We've created an agreement in Anchor, a billing platform that we use to automate the payment process so both of us can save time and effort.\n\nIt includes a detailed description of the services we agreed on, the price, and the billing terms.\n\nPlease review and approve the agreement.";
export function contactHasAgreement(contact, agreements, proposals) {
  if (!contact.customerId()) return false;
  const hasContact = r => !r.isSample && r.customer.id === contact.customerId();
  return !!agreements && agreements.some(hasContact) || !!proposals && proposals.some(hasContact);
}