function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { LineItemType } from "./model";
import Decimal from 'decimal.js';
import { serviceModel } from "./..";
import { timeModel } from "./..";
import { AvailableCreditModel } from "../AvailableCreditModel";
export function fromDTO(invoiceDraftDTO) {
  const {
    issueDate,
    paymentDate,
    totalAmount
  } = invoiceDraftDTO;
  const credits = invoiceDraftDTO.credits.map(c => new AvailableCreditModel(c));
  const lineItems = invoiceDraftDTO.lineItems.map(lineItemFromDTO);
  return {
    issueDate: timeModel.fromDatestampObject(issueDate),
    paymentDate: timeModel.fromDatestampObject(paymentDate),
    totalAmount: new Decimal(totalAmount).div(100).toDP(),
    credits,
    lineItems
  };
}
export function lineItemFromDTO(dto) {
  switch (dto.source) {
    case LineItemType.Automatic:
      {
        return _objectSpread(_objectSpread({}, dto), {}, {
          source: LineItemType.Automatic,
          totalAmount: new Decimal(dto.totalAmount).div(100).toDP(),
          rate: _objectSpread(_objectSpread({}, dto.rate), {}, {
            amount: new Decimal(dto.rate.amount).div(100).toDP()
          }),
          automatic: _objectSpread(_objectSpread({}, dto.automatic), {}, {
            discount: serviceModel.discountFromDTO(dto.automatic.discount)
          })
        });
      }
    case LineItemType.Manual:
      {
        return _objectSpread(_objectSpread({}, dto), {}, {
          source: LineItemType.Manual,
          totalAmount: new Decimal(dto.totalAmount).div(100).toDP(),
          rate: _objectSpread(_objectSpread({}, dto.rate), {}, {
            amount: new Decimal(dto.rate.amount).div(100).toDP()
          }),
          manual: _objectSpread(_objectSpread({}, dto.manual), {}, {
            quantity: dto.manual.quantity ? new Decimal(dto.manual.quantity).div(100).toDP() : null,
            discount: serviceModel.discountFromDTO(dto.manual.discount)
          })
        });
      }
    case LineItemType.AdHocCharge:
      {
        return _objectSpread(_objectSpread({}, dto), {}, {
          source: LineItemType.AdHocCharge,
          totalAmount: new Decimal(dto.totalAmount).div(100).toDP(),
          rate: _objectSpread(_objectSpread({}, dto.rate), {}, {
            amount: new Decimal(dto.rate.amount).div(100).toDP()
          }),
          adHocCharge: _objectSpread(_objectSpread({}, dto.adHocCharge), {}, {
            quantity: new Decimal(dto.adHocCharge.quantity).div(100).toDP(),
            discount: serviceModel.discountFromDTO(dto.adHocCharge.discount)
          })
        });
      }
    case LineItemType.Credit:
      {
        return _objectSpread(_objectSpread({}, dto), {}, {
          source: LineItemType.Credit,
          totalAmount: new Decimal(dto.totalAmount).div(100).toDP(),
          rate: _objectSpread(_objectSpread({}, dto.rate), {}, {
            amount: new Decimal(dto.rate.amount).div(100).toDP()
          })
        });
      }
    default:
      {
        throw new Error('unrecognized line item source');
      }
  }
}
export function toDTO(invoiceDraft) {
  const {
    issueDate,
    paymentDate,
    totalAmount
  } = invoiceDraft;
  const credits = invoiceDraft.credits.map(c => c.toJSON());
  const lineItems = invoiceDraft.lineItems.map(lineItemToDTO);
  return {
    issueDate: timeModel.toDatestampObject(issueDate),
    paymentDate: timeModel.toDatestampObject(paymentDate),
    totalAmount: totalAmount.mul(100).toNumber(),
    credits,
    lineItems
  };
}
export function lineItemToDTO(lineItem) {
  switch (lineItem.source) {
    case LineItemType.Automatic:
      {
        return _objectSpread(_objectSpread({}, lineItem), {}, {
          source: LineItemType.Automatic,
          totalAmount: lineItem.totalAmount.mul(100).toNumber(),
          rate: _objectSpread(_objectSpread({}, lineItem.rate), {}, {
            amount: lineItem.rate.amount.mul(100).toNumber()
          }),
          automatic: _objectSpread(_objectSpread({}, lineItem.automatic), {}, {
            discount: serviceModel.discountToDTO(lineItem.automatic.discount)
          })
        });
      }
    case LineItemType.Manual:
      {
        return _objectSpread(_objectSpread({}, lineItem), {}, {
          source: LineItemType.Manual,
          totalAmount: lineItem.totalAmount.mul(100).toNumber(),
          rate: _objectSpread(_objectSpread({}, lineItem.rate), {}, {
            amount: lineItem.rate.amount.mul(100).toNumber()
          }),
          manual: _objectSpread(_objectSpread({}, lineItem.manual), {}, {
            quantity: lineItem.manual.quantity !== null ? lineItem.manual.quantity.mul(100).toNumber() : null,
            discount: serviceModel.discountToDTO(lineItem.manual.discount)
          })
        });
      }
    case LineItemType.AdHocCharge:
      {
        return _objectSpread(_objectSpread({}, lineItem), {}, {
          source: LineItemType.AdHocCharge,
          totalAmount: lineItem.totalAmount.mul(100).toNumber(),
          rate: _objectSpread(_objectSpread({}, lineItem.rate), {}, {
            amount: lineItem.rate.amount.mul(100).toNumber()
          }),
          adHocCharge: _objectSpread(_objectSpread({}, lineItem.adHocCharge), {}, {
            quantity: lineItem.adHocCharge.quantity.mul(100).toNumber(),
            discount: serviceModel.discountToDTO(lineItem.adHocCharge.discount)
          })
        });
      }
    case LineItemType.Credit:
      {
        return _objectSpread(_objectSpread({}, lineItem), {}, {
          source: LineItemType.Credit,
          totalAmount: lineItem.totalAmount.mul(100).toNumber(),
          rate: _objectSpread(_objectSpread({}, lineItem.rate), {}, {
            amount: lineItem.rate.amount.mul(100).toNumber()
          })
        });
      }
    default:
      {
        throw new Error('unrecognized line item source');
      }
  }
}