import { fullName, getUserInitials } from "../infra/utils";
import { UserRole } from "../store/store.model";
export class MemberModel {
  constructor(dto) {
    this.dto = dto;
  }
  id() {
    return this.dto.id;
  }
  firstName() {
    return this.dto.firstName;
  }
  lastName() {
    return this.dto.lastName;
  }
  fullName() {
    return fullName(this.dto.firstName, this.dto.lastName);
  }
  email() {
    return this.dto.email;
  }
  initials() {
    return getUserInitials(this.dto);
  }
  role() {
    return this.dto.role;
  }
  invitedRole() {
    return this.dto.invitedRole;
  }
  effectiveRole() {
    return this.dto.role === 'invited' ? this.dto.invitedRole : this.dto.role;
  }
  isOwner() {
    return this.dto.role === UserRole.Owner;
  }
  isInvited() {
    return this.dto.role === 'invited';
  }
  isAssignable(isVendor) {
    return this.dto.role !== 'invited' && (isVendor || this.dto.role !== UserRole.Member);
  }
}