import { BillingTriggerType } from "../../types/api.model";
import { ServiceTemplateStatus } from "./model";
import * as serviceSelectors from "../service/selectors";
import { DiscountType } from "../DiscountModel";
export function isDeleted(serviceTemplate) {
  return serviceTemplate.status === ServiceTemplateStatus.Deleted;
}
export const isRangePrice = serviceSelectors.isRangePrice;
export const isFixedPrice = serviceSelectors.isFixedPrice;
export const isVariablePrice = serviceSelectors.isVariablePrice;
export const discount = serviceSelectors.discount;
export const price = serviceSelectors.price;
export function unitName(serviceTemplate) {
  return serviceSelectors.unitName({
    cost: serviceTemplate.cost
  });
}
export function unitCap(serviceTemplate) {
  return serviceSelectors.unitCap({
    cost: serviceTemplate.cost
  });
}
export function isPercentDiscount(discount) {
  return !!discount && discount.type === DiscountType.Percent;
}
export function isValueDiscount(discount) {
  return !!discount && discount.type === DiscountType.Value;
}
export function isOnApproval(billing) {
  return billing.trigger === BillingTriggerType.OnApproval;
}
export function isOneTimeManual(billing) {
  return billing.trigger === BillingTriggerType.OneTimeManual;
}
export function isOneTimeDelivery(billing) {
  return billing.trigger === BillingTriggerType.OneTimeDelivery;
}
export function isOngoing(billing) {
  return billing.trigger === BillingTriggerType.Ongoing;
}
export function isRepeatableManual(billing) {
  return billing.trigger === BillingTriggerType.RepeatableManual;
}
export function isManual(billing) {
  return isOneTimeDelivery(billing) || isOneTimeManual(billing) || isRepeatableManual(billing);
}
export function isRecurring(billing) {
  return isRepeatableManual(billing) || isOngoing(billing);
}