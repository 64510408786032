import { IntegrationSource, IntegrationStatus } from "../../types/api.model";
export function qboIntegration(integrations) {
  return integrations === null || integrations === void 0 ? void 0 : integrations.find(isQboIntegration);
}
export function isQboIntegration(integration) {
  return integration.source === IntegrationSource.Qbo;
}
export function financialCentsIntegration(integrations) {
  return integrations === null || integrations === void 0 ? void 0 : integrations.find(isFinantialCentsIntegration);
}
export const karbonIntegration = integrations => integrations === null || integrations === void 0 ? void 0 : integrations.find(isKarbonIntegration);
export const karbonDefaultAssignee = integration => isKarbonIntegration(integration) ? integration.karbon.defaultAssigneeId : undefined;
export const clientHubIntegration = integrations => integrations === null || integrations === void 0 ? void 0 : integrations.find(isClientHubIntegration);
export function isFinantialCentsIntegration(integration) {
  return integration.source === IntegrationSource.FinancialCents;
}
export const isKarbonIntegration = integration => integration.source === IntegrationSource.Karbon;
export const isClientHubIntegration = integration => integration.source === IntegrationSource.ClientHub;
export function isLinked(integration) {
  return integration.status === IntegrationStatus.Linked;
}