import { useGlobalContext } from 'seagull';
import { useHistory } from 'react-router-dom';
import { handlePromise, sendErrReport } from "../infra/utils";
import { useIoContext, useStoreActions, useStoreStatic } from "../contexts";
import { routes } from "../constants";
import { selectors } from "../store/state-selectors";
import { useHomePage } from "../pages/HomePage/HomePageProvider";
import * as Sentry from '@sentry/react';
const loaderName = 'switch-business';
export const prevBusinessIdKey = 'prevBusinessId';
export function useSwitchBusiness() {
  const history = useHistory();
  const globalContext = useGlobalContext();
  const {
    getState
  } = useStoreStatic();
  const {
    api,
    setAuthToken,
    resetBusinessData,
    logBI
  } = useStoreActions();
  const {
    localStorage
  } = useIoContext();
  const {
    setHidden
  } = useHomePage();
  function getPrevBusinessId() {
    return localStorage.getItem(prevBusinessIdKey);
  }
  async function switchBusiness(businessId) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    if (!businessId) {
      globalContext.showErrorToast('Failed to switch business', 'switch-business-failure');
      history.push(routes.logout);
      return;
    }
    const prevBusinessId = selectors.user.businessId(getState());
    globalContext.startLoader(loaderName);
    const authToken = await handlePromise(api.user.switchBusiness(businessId));
    logBI(10354);
    if (authToken.data) {
      setHidden(true);
      prevBusinessId && localStorage.setItem(prevBusinessIdKey, prevBusinessId);
      resetBusinessData();
      Sentry.addBreadcrumb({
        type: 'switchBusiness',
        data: {
          businessId,
          prevBusinessId
        }
      });
      try {
        await setAuthToken(authToken.data);
        history.push(options.newRoute || routes.home);
      } catch (e) {
        sendErrReport('Failed to switch business');
        history.push(routes.logout);
      }
      setHidden(false);
    }
    globalContext.stopLoader(loaderName);
  }
  return {
    switchBusiness,
    getPrevBusinessId
  };
}