import { paymentMethodModel } from "../../models";
export function isSelectablePaymentMethod(paymentMethod, isTerminatedAgreement, isVendor, isSelected, isProposal, selectedPMRequiresVerification) {
  return !isTerminatedAgreement && !isVendor && !isSelected && !(paymentMethodModel.isBankAccount(paymentMethod) && paymentMethodModel.isErroredBankAccount(paymentMethod.bankAccount)) && !paymentMethodModel.isFailedDueToDuplicate(paymentMethod) && (isProposal || selectedPMRequiresVerification ? paymentMethodModel.requiresVerification(paymentMethod) || paymentMethodModel.isVerified(paymentMethod) : paymentMethodModel.isVerified(paymentMethod));
}
export async function fetchPaymentMethods(_ref) {
  let {
    isPreview,
    isSample,
    isProposal,
    vendorId,
    businessId,
    VENDOR_CONTROL_PM,
    initialSelectedPaymentMethodId,
    getSelectedPaymentMethod,
    vendorGetPaymentMethod,
    clientGetPaymentMethod
  } = _ref;
  if (isPreview || isSample) {
    return {
      paymentMethods: []
    };
  }
  const isVendorInRelationship = businessId === vendorId;
  if (isVendorInRelationship && !VENDOR_CONTROL_PM) {
    const selectedPaymentMethod = await getSelectedPaymentMethod();
    return {
      // Vendor gets only the selected payment method of his client per relationship.
      paymentMethods: selectedPaymentMethod ? [selectedPaymentMethod] : [],
      selectedFundingSourceId: selectedPaymentMethod === null || selectedPaymentMethod === void 0 ? void 0 : selectedPaymentMethod.id
    };
  } else {
    const fetchSelectedFundingSourceId = isProposal ? Promise.resolve(initialSelectedPaymentMethodId) : getSelectedPaymentMethod().then(selected => selected === null || selected === void 0 ? void 0 : selected.id);
    const [paymentMethods, selectedFundingSourceId] = await Promise.all([VENDOR_CONTROL_PM && isVendorInRelationship ? vendorGetPaymentMethod() : clientGetPaymentMethod(), fetchSelectedFundingSourceId]);
    return {
      paymentMethods,
      selectedFundingSourceId
    };
  }
}
export function createPaymentSetupHandlers(_ref2) {
  let {
    isVendor,
    VENDOR_CONTROL_PM,
    paymentMethodHandlers,
    customerId,
    vendorId
  } = _ref2;
  const createSetupIntent = () => VENDOR_CONTROL_PM ? isVendor ? paymentMethodHandlers.vendorCreateSetupIntent(customerId) : paymentMethodHandlers.clientCreateSetupIntent(vendorId) : paymentMethodHandlers.createSetupIntent(vendorId);
  const addToBusiness = paymentMethodId => isVendor && VENDOR_CONTROL_PM ? paymentMethodHandlers.vendorAddToBusiness(paymentMethodId, customerId) : paymentMethodHandlers.clientAddToBusiness(paymentMethodId, vendorId);
  return {
    createSetupIntent,
    addToBusiness
  };
}